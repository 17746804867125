.quizz-item {
    position: relative;
}

.quizz-item-img {
    width: 100%;
    position: absolute;
}

.quizz-item-description {
    position: relative;
    width: 100%;
    text-align: center;
    padding-top: 80px;

    color: white;
    text-shadow: black 0px 0px 5px;
    font-weight: 500;
    font-size: 15pt;

}