.elements-container {
  margin: auto;
  width: 900px;
}

.element {
  width: 900px;
  text-align: center;
  height: 60vh;
  display: inline-block;
  margin-bottom: 150px;
}

.element-img {
  max-width: 900px;
  height: 60vh;
  background-color: #fff;
}

.element-img-text {
  width: 900px;
  background-color: #fff;
}

.element-desc {
  position: absolute;

  margin-left: 15px;
  margin-top: 15px;

  color: white;
  text-shadow: black 0px 0px 5px;
  font-weight: 500;
}

.element-desc-title {
  margin-top: 15px;
  opacity: 0;
  transition: opacity .5s;
}

.element:hover .element-desc-title {
  margin-top: 15px;
  opacity: 1;
}