.scroll-indicator-ref {
  position: relative;
}

.scroll-indicator {
  position: absolute;
  height: 200px;
  width: 1px;
  background-color: #fff;
}
.scroll-indicator-text {
  position: absolute;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: -50px;
  line-height: 0px;
  color: #fff;
  user-select: none;
}

.scroll-indicator-bullet-container {
  position: absolute;
  transition: margin 0s linear;
}
.scroll-indicator-bullet {
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 5px;
  background-color: #fff;
}