@import './mixins';

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #17181a;

  overflow-y: scroll; // Always show the scrollbar to avoid flikering the screen on animation (cheap way to fix the issue)
}

a {
  color: inherit;
  text-decoration: none;
}

.app-header {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vw);
  color: #fff;
}

.hamburger-button {
  position: fixed;
  color: white;
  margin: 20px 0 0 23px;
  display: none;
}

@media only screen and (max-width: 768px) {
  .hamburger-button {
    z-index: 1001;
    display: inherit;
    transform: rotate(90deg);
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  .hamburger-button.active {
    transform: rotate(180deg);
  }
}

.navigation-wrapper {
  top: -0;
  right: 0;
  position: fixed;
  width: 100%;
  background-image: url("/bg.gif");
  z-index: 1000;
  transition: top .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.navigation-wrapper.hidden {
  top: -80px;
}

@media only screen and (max-width: 768px) {
  .navigation-wrapper {
    background-image: none;
    pointer-events: none;
  }

  .navigation-wrapper.hidden {
    top: -0;
  }
}

.navigation {
  right: 0;
  padding: 1rem 0;
  display: table;
  float: right;
}

@media only screen and (max-width: 768px) {
  .navigation {
    float: left;
    margin-top: 40px;
    margin-left: 0;
    transition: margin 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  .navigation-wrapper.hidden-mobile .navigation {
    margin-left: -150px;
  }
}

.navigation>ul {
  margin: 0 1.5rem 0 0;
  padding: 0;
  list-style-type: none;
}

@media only screen and (max-width: 768px) {
  .navigation.small>ul {
    display: flex;
    flex-direction: column;
  }
}

.navigation>ul>li {
  @include smooth-underline(calc(12px + 0.7vw));
  //mix-blend-mode: difference;
  color: #fff;
  position: relative;
  display: inline-block;
  margin: 0 1.5rem;
  font-size: calc(10px + 0.7vw);
  pointer-events: auto;

  &::before {
    transition-property: width;
    content: '';
    display: block;
    position: absolute;
    top: calc(12px + 0.7vw);
    width: 0;
    right: 0;
    height: 1px;
    background: #fff;
    transition: width .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  &:hover::before {
    width: 100%;
  }

  &.selected::before {
    width: 100%;
  }
}

// https://github.com/maisano/react-router-transition
.switch-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: height .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

  // Hide scrollbar
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    /* Safari and Chrome */
    height: 0;
  }
}

.switch-wrapper>div {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

/* ************* vertical timeline css modifs ********* */

.vertical-timeline-element-icon {
  transition: transform 0.5s;
}

.vertical-timeline-element-icon:hover {
  transform: scale(1.2);
}

.vertical-timeline-element-icon>img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@media (max-width: 1170px) {
  .vertical-timeline-element-icon>img {
    width: 40px;
    height: 40px;
  }
}

/* ***************** */

.scroll-indicator-wrapper {
  position: fixed;
  right: 50px;
  top: 30%;
  z-index: 100;
  transition: right .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

@media (max-width: 1170px) {
  .scroll-indicator-wrapper {
    right: 10px;
  }
}

.scroll-indicator-wrapper.hidden {
  right: -10px;
}

/* *********** */

.container {
  margin: auto;
  width: 900px;
}

.article {
  color: #fff;

  h1 {
    text-align: center;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 33%;
  }
}

/**************************/
/* CSS Animations Classes */
/**************************/

// From :https://codesandbox.io/s/animated-routes-demo-react-router-v6-6l1li?file=/src/index.tsx

/*
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}