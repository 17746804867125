.quizz {
  position: relative;
  width: 50vw;
  padding-top: 100px;
  margin: auto;
}

@media (max-width: 1170px) {
  .quizz {
    width: 80vw;
  }
}

.quizz-work {
  position: absolute;
  width: 100%;
}

.quizz-btn-container {
  z-index: 100;
  position: absolute;
  margin: auto;
  right: 0;
}

.quizz-btn {
  text-shadow: black 0px 0px 5px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  margin: 15px;
  border: white solid 1px;
  color: white
}

@media (max-width: 860px) {
  .quizz-btn {
    padding: 3px 6px 3px 6px;
    margin: 5px;
  }
}
