@import '../mixins';

.home {
  position: relative;
  background-image: url("/bg.gif")
}

.name {
  font-size: calc(10px + 3vw);
}

.particle-background {
  top: 0;
  position: fixed;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;
  height: 100%;
}

.home-social-buttons {
  z-index: 100;
  margin-left: -5px;
  padding: 0;
  list-style: none;

  >li {
    @include smooth-underline(calc(15pt + 5px));
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 15pt;
  }
}

.app-header {
  >span {
    z-index: 100;
  }
}

.pgp-key {
  z-index: 10000;
  font-size: calc(10px + 2vw);
  transition: color 4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

  &:hover {
    color: #b6ff7b;
  }
}